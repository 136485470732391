body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root-cw {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
* {
    box-sizing: border-box;
}

#root-cw {
    position: relative;
}

.ACCW_Button_Main {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ACCW_Button_IMG_wrapper {
    height: 90%;
    width: 90%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}

.ACCW_Button_IMG {
    height: 20%;
    transition: all 0.2s;
}

.ACCW_Button_IMG-animation {
    height: 100%;
}
.ACCW_Button_IMG_close {
    height: 30%;
    transition: all 0.2s;
}

.ACCW_Button_IMG_close-turn {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
}

.ACCW_NotificationComponent {
    position: absolute;
    z-index: 10;
    width: 22px;
    height: 22px;
    background-color: #ff6f61;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -2px;
    right: 0px;
}

.ACCW_NotificationComponent_span {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.ACCW_LastImgComponent {
    position: absolute;
    bottom: 70px;
    right: 1px;

    max-width: 375px;
    transition: all 0.4s;
}

.ACCW_LastImgComponent_wrappers {
    width: 295px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    position: relative;
}

.ACCW_LastImgComponent_message {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.ACCW_LastImgComponent_close_button {
    min-width: 23px;
    min-height: 23px;
    max-width: 25px;
    max-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    right: -10px;
    background: #ff6f61;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
}

.ACCW_LastImgComponent_close_button img {
    width: 10px;
}

.ACCW_LastImgComponent_message_text {
    margin: 0;
    padding: 0;

    cursor: pointer;
    text-align: left;

    color: #000000;
    font-family: 'Montserrat', sans-serif;

    font-size: 12px;
    font-weight: 400;
    padding-left: 22px;
    white-space: pre-wrap;
    max-width: 295px;
    padding-top: 15px;
    padding-bottom: 15px;
    word-break: break-word;
    padding-right: 22px;
}

@media screen and (max-width: 650px) {
}

.ACCW_LoaderComponent{
  height: 0px;
  display: flex;
  justify-content: center;
}
/* windmill */
.spinner {
  width: 25px;
  height: 25px;
  position: relative;
  margin-top: 5px;
}

.cube1, .cube2 {
  background-color: #f99b1b;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(14px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(14px) translateY(14px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { 
    transform: translateX(14px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(14px) rotate(-90deg) scale(0.5);
  } 50% { 
    transform: translateX(14px) translateY(14px) rotate(-179deg);
    -webkit-transform: translateX(14px) translateY(14px) rotate(-179deg);
  } 50.1% { 
    transform: translateX(14px) translateY(14px) rotate(-180deg);
    -webkit-transform: translateX(14px) translateY(14px) rotate(-180deg);
  } 75% { 
    transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5);
  } 100% { 
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}


/* windmill end */

/* cube */
.sk-cube-grid {
  width: 30px;
  height: 30px;
  margin: 20px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #f99b1b;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
          border-radius: 5px 0 0 0;
        }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        
        }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
          border-radius: 0 5px 0 0;
        
        }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
          border-radius: 0 0 0 5px;
         }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; 
          border-radius: 0 0 5px 0;
        
        }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

/* cube */
.ACCW_ConversationComponent {
    flex: 1 1;
    height: 1px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;


    /* overflow: auto;
    overflow-y: auto;
    overflow-y: overlay; */
    /* overflow: auto; */
}

@-moz-document url-prefix() {
    .ACCW_ConversationComponent {
        flex-direction: column;
        scrollbar-color: #bed1e8 #edf5ff;
        scrollbar-width: thin;
    }
}
.ACCW_ConversationComponent_revers {
    height: 100%;
}

.ACCW_ConversationComponent::-webkit-scrollbar {
    width: 4px;
    display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ACCW_ConversationComponent:hover::-webkit-scrollbar {
    display: block;
}

.ACCW_ConversationComponent::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #edf5ff;
}

.ACCW_ConversationComponent::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #bed1e8;
}

.ACCW_ConversationComponent::-webkit-scrollbar-thumb:hover {
    /* background: #edf5ff;  */
}

/* for meesege wraper */

.ACCW_UnreadMessege_messeg {
    display: flex;
    justify-content: center;
    /* margin-bottom: -30px;
    margin-top: -20px; */
    height: 30px;
    align-items: center;
}

.ACCW_UnreadMessege_messeg_text {
    background-color: #f7f7f7;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.ACCW_UnreadMessege_messeg_textStyle {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.87;
}

.ACCW_ErrorComponent_main {
    position: absolute;
    width: 100%;
    min-height: 21px;
    background: #ff6f61;
    z-index: 10;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ACCW_ErrorComponent_Text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 12px;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_gradient {
    width: 100%;
    background-image: linear-gradient(to right, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}

.accw_input_gradient::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.87);
}


.ACCW_MessegeButtonComponent{
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
 
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;

    position: relative;
    z-index: 2;
}

.ACCW_MessegeButtonComponent_link{
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
    text-decoration: none;
    position: relative;
    z-index: 2;
}

.ACCW_MessegeButtonComponent_text_link{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    word-break: break-word;
    padding: 0 30px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ACCW_MessegeButtonComponent_text{

    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    word-break: break-word;
    padding: 0 12px;
}

.ACCW_MessegeButtonComponent_main{
    position: relative;
    background: black;
}

.ACCW_MessegeButtonComponent_animation{
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    /* background: rgba(250,250,250, 0.5);  */
    background: rgba(187, 225, 239, 0.3);
    
     /* background: rgba(0,0,0, 0.3); */
    z-index: 1;
    /* top: 100%;
    left: 100%; */
    /* bottom: 0; */
    /* transform: translate(-50%, -50%); */
    transition: all 0.6s;
    opacity: 0;
    top: 0;
    left: 0;
}

.ACCW_MessegeButtonComponent_main:active .ACCW_MessegeButtonComponent_animation{
    /* height: 100%;
    width: 100%; */
    transition: all 0s;
    opacity: 1;

}
.ACCW_GalleryMessageComponent{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 10px;
    position: relative;
}

.ACCW_CardGalleryComponent_swiper_button{
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    opacity: 0.77;
    top: 20%;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.ACCW_CardGalleryComponent_swiper_button:hover{
    opacity: 1;
}

.ACCW_CardGalleryComponent_swiper_button:hover i{
    color: #006cff;
    opacity: 1;

}

.ACCW_GalleryMessageComponentWrapper{
    display: flex;
}

.ACCW_CardGalleryComponent_right{
    right: 10px;
}

.ACCW_CardGalleryComponent_left{
    left: 10px;

}

.ACCW_CardGalleryComponent_left i{
    margin-right: 3px;
   opacity: 0.38;

}

.ACCW_CardGalleryComponent_right i{
    margin-left: 3px;
   opacity: 0.38;
    
}

.ACCW_CardGalleryComponent{
    /* Style for "Прямоуголь" */
    min-width: 220px;
    max-width: 220px;
    min-height: 230px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swiper-button-disabled{
    /* opacity: 0;
    cursor: default; */
    display: none;
}



.gallery_button {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
}


.gallery_button .ACCW_TextMessegeComponent_button_wrapper {
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
}

.gallery_button .ACCW_MessegeButtonComponent_link {
    border-bottom: 1px solid #f2f2f2;
}

.gallery_button .ACCW_MessegeButtonComponent{
    border-bottom: 1px solid #f2f2f2;
}

.ACCW_CardGalleryComponent_img{
    max-height: 125px;
}

.ACCW_CardGalleryComponent_img_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    height: 125px;
}

.ACCW_CardGalleryComponent_text_title{
    color: #000000;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 10px;
    margin-bottom: 3px;
}

.ACCW_CardGalleryComponent_text_description{
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    word-break: break-word;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    padding-right: 10px;
}

.ACCW_CardGalleryComponent_text_wrapper{
    padding-top: 10px;
    padding-left: 10px;
}
.ACCW_ImgMessegeComponent_Text_wrapper{
    padding: 10px;
}

.ACCW_ImgMessegeComponent_img{
    max-width: 100%;
}
.AudioPlayerComponent_player_wrapper{
    border-radius: 4px;
    display: flex;
    margin-bottom: 4px;
}

.AudioPlayerComponent_Button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.AudioPlayerComponent_play_Button img{
    height: 14px;

}

.AudioPlayerComponent_pause_Button img{
    height: 13px;
}

.AudioPlayerComponent_play_Button, .AudioPlayerComponent_pause_Button{
    cursor: pointer;
}

.AudioPlayerComponent_range_text{
    display: flex;
    align-items: center;
    justify-content: center;


}

.AudioPlayerComponent_range_text span{
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.87;
    font-family: 'Roboto Mono', monospace;
    /* white-space: pre; */
}



.AudioPlayerComponent_range_audio{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 19px;
    margin-right: 15px;
}



.audioplayer_input_range {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    /* border-radius: 5px;    */
    background-color: #ffffff;
    outline: none;
    /* opacity: 0.7; */
    transition: opacity .2s;
    cursor: pointer;

  }
  
  .audioplayer_input_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .audioplayer_input_range::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #ffffff;
    cursor: pointer;
  }

  .AudioPlayerComponent_valuem_Button{
      display: flex;
      align-items: center;
      width: 40px;
      display: flex;
      justify-content: center;
      align-content: center;

  }

 

  .AudioPlayerComponent_range_valuem_Button_icons{
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.3s;
    cursor: pointer;
    position: absolute;
    right: 13px;
    justify-content: flex-end;
    padding: 0 10px;
}

  

  .AudioPlayerComponent_range_valuem_Button_icons:hover{
    background: rgb(3, 164, 218);
    width: 150px;

  }


  .AudioPlayerComponent_range_valuem_Button_icon_on{

    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 2;
  }

  .AudioPlayerComponent_range_valuem_Button_iconoff{
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 2;

  }

  .AudioPlayerComponent_range_valuem_wrapper{
   opacity: 0;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }


  .AudioPlayerComponent_little_width{
      width: 30px;
  }

  .AudioPlayerComponent_range_valuem_Button_icons:hover .AudioPlayerComponent_range_valuem_wrapper{
      opacity: 1;
  }

  .AudioPlayerComponent_title_wrapper{
    display: flex;
    margin-bottom: -10px;
  }

  .AudioPlayerComponent_title_wrapper_text span{
    font-family: Montserrat,sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    justify-content: center;
    display: block;
  }

  .AudioPlayerComponent_download_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 10px; */
  }

  .AudioPlayerComponent_download_wrapper_img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
  }
  .AudioPlayerComponent_download_wrapper_img img {
    width: 28px;
  }
  .AudioPlayerComponent_title_wrapper_text{
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .AudioPlayerComponent_main{
    padding-top: 10px;
    padding-right: 10px;
  }



  .VideoPlayerComponent_video_wrapper{
    position: relative;
    border-radius: 4px;
    overflow: hidden;

  }

  .VideoPlayerComponent_control_wrapper{
    position: absolute;
    height: 40px;
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,191,255, 0.87);
    bottom: 4px;
    width: 100%;
    opacity: 0;
    height: 0;
    transition: all 0.3s;
    display: flex;

/* 
    opacity: 1;
    height: 40px; */
  }

  .VideoPlayerComponent_video_wrapper:hover .VideoPlayerComponent_control_wrapper {
    opacity: 1;
    height: 40px;
  }

  .VideoPlayerComponent_main{
    padding-top: 10px;
    padding-right: 10px;
  }

  .FileComponet_main{
    padding-top: 10px;
    padding-right: 10px;
    max-width: 100%;

  }

  .AudioPlayerComponent_file_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
.ACCW_TextMessegeComponent_Text_wrapper{
    padding: 15px 29px;
    padding-left: 0;
    padding-bottom: 0;
}

.ACCW_TextMessegeComponent_Text_wrapper_userMess{
    padding: 13px 21px;
    padding-right: 0px;
    margin-right: 60px;
    padding-bottom: 0;
}

.ACCW_TextMessegeComponent_Text{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    justify-content: center;
    white-space: pre-wrap;
    color: #0d1d33;

    display: block;
}

.ACCW_TextMessegeComponent_Text_userMess{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    justify-content: center;
    color: #000000;
    white-space: pre-wrap;

    display: block;
}

.ACCW_TextMessegeComponent_button_r{
    padding-right: 29px;
    padding-top: 10px;
}

.ACCW_TextMessegeComponent_button_wrapper{
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
}






.message_link_bot{
    color: #5e8ea4;
    text-decoration: none;
    transition: all 0.2s;
}

.message_link_user{
    color: #5e8ea4;
    text-decoration: none;
    transition: all 0.2s;

}
.message_link_bot:hover{
    text-decoration: underline;
}

.message_link_user:hover{
    text-decoration: underline;

}


.mark_ACCW_italic{
    font-style: italic;
    background: none;
}

.mark_ACCW_bold{
    font-weight: bold;
    background: none;

}
.ACCW_MessegeBotComponent_messege_wrapper_user{
    padding: 0 10px;
    padding-bottom: 20px;
    display: flex;
    padding-left: 0px;

    grid-template-columns: 1fr minmax(0px, 724px) 1fr;
    display: grid;
    /* justify-items: end; */
}
.ACCW_MessegeBotComponent_messege_wrapper_user:first-child{
    padding-top: 30px;
  }

.ACCW_MessegeBotComponent_messege_wrapper_user:last-child{
    padding-bottom: 0px;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack{
    padding-bottom: 5px;

}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent_arrow_right{
    display: none;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent{
    border-radius: 0px 10px 10px 0px;

}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent_messege_wrapper-avatar{
    opacity: 0;
}


.ACCW_MessegeBotComponent_messege_wrapper-avatar{
    height: 40px;
    width: 40px;
    display: flex;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
}

.ACCW_MessegeBotComponent_messege_avatar{
    height: 100%;
    margin: auto;
}

.ACCW_MessegeBotComponent{
    /* background-color: #f7f9fa; */
    border-radius: 0px 10px 10px 0px;
    position: relative;
    max-width: calc( 100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
    padding-bottom: 10px;
}

.ACCW_MessegeBotComponent_arrow_right{
    position: absolute;
    bottom: 0px;
    left: -10px;
}

/* delete */
.ACCW_MessegeBotComponentt_text_messege{
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent_messege_avatar{
    opacity: 0;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent{
    border-radius: 10px 10px 10px 10px;
}
.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent_arrow_right{
    display: none;
}
.ACCW_MessegeBotComponent_messege_avatar_typing{
    height: 90%;
    margin: auto;
}

.ACCW_MessegeBotComponent_arrow_right_typing{
    position: absolute;
    bottom: 0px;
    left: -10px;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing_border .ACCW_MessegeBotComponent{
    
    border-radius: 10px 10px 10px 0px!important;
}

.ACCW_MessegeBotComponent_timestamp{
    font-family: 'Montserrat', sans-serif;
    color: rgba(0,0,0, 0.54);
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
}
.ACCW_MessegeUserComponent_messege_wrapper_user {
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    padding-bottom: 20px;

    grid-template-columns: 1fr minmax(0px, 724px) 1fr;
    display: grid;
    justify-items: end;
}
.ACCW_MessegeUserComponent_messege_wrapper_user:first-child {
    padding-top: 30px;
}

.ACCW_MessegeUserComponent_messege_wrapper_user:last-child {
    padding-bottom: 0px;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack {
    padding-bottom: 5px;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack .ACCW_MessegeUserComponent_arrow_right {
    display: none;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack .ACCW_MessegeUserComponent {
    border-radius: 10px 0px 0px 10px;
}

.ACCW_MessegeUserComponent {
    background-color: #edf5ff;
    text-align: center;

    border-radius: 10px 0px 0px 10px;
    position: relative;
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
}

.ACCW_MessegeUserComponent_arrow_right {
    position: absolute;
    right: -10px;
    bottom: 0px;
}

.ACCW_MessegeUserComponent_text_messege {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 1em;
    font-weight: 300;
    text-align: left;
    word-break: break-word;
    display: flex;
}

.ACCW_MessegeBotComponent_messege_wrapper_img_wrapper {
    display: flex;
    align-items: flex-end;
    background-color: #fafafa;
    padding-left: 13px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ACCW_MessegeUserComponent_status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 50px;
    padding: 10px 0;
    padding-bottom: 13px;
    padding-left: 20px;
}

.imgMessegeWraperFalse {
    display: flex;
    align-items: center;
}

.imgMessegeWraperFalse img {
    margin-right: 10px;
}

.imgMessegeWraperFalse img {
    cursor: pointer;
}

.ACCW_MessegeUserComponent_not_sending {
    background-color: #00bffe;
}

.ACCW_MessegeUserComponent_not_sending .ACCW_TextMessegeComponent_Text_userMess {
    color: white;
}

.ACCW_MessegeUserComponent_messege_wrapper_status {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ACCW_MessegeUserComponent_status_timestamp {
    font-family: 'Montserrat';
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
}

.ACCW_MessegeUserComponent_message_gradien {
    width: 100%;
    background-image: linear-gradient(to right, #edf5ff 0%, rgba(237, 245, 255, 0) 100%);
}

.ACCW_gradient .ACCW_TextMessegeComponent_Text_userMess {
    color: #ffffff;
    font-weight: 400;
}

.ACCW_gradient .ACCW_MessegeUserComponent_status_timestamp {
    color: rgba(255, 255, 255, 0.54);
}

.ACCW_gradient .ACCW_TextMessegeComponent_Text {
    color: #ffffff;
    font-weight: 400;
}

.ACCW_gradient .ACCW_MessegeBotComponent_messege_wrapper_img_wrapper {
    background-color: rgba(255, 255, 255, 0.2);
}

.ACCW_gradient .ACCW_MessegeBotComponent {
    background-color: rgba(255, 255, 255, 0.2);
}

.ACCW_gradient .ACCW_MessegeBotComponent_messege_wrapper_user_gradient {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2));
}

.ACCW_gradient .ACCW_MessegeBotComponent_timestamp {
    color: rgba(255, 255, 255, 0.54);
}

.ACCW_gradient .message_link_bot {
    color: #0d1d33;
}

.ACCW_gradient .message_link_user {
    color: #0d1d33;
}

.ACCW_TypingComponent{
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: space-between;
  margin: 15px 5px;
  margin-bottom: 7px;
  margin-right: 29px;
}

.ACCW_TypingComponent_point{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #00bffe;
}

.ACCW_gradient .ACCW_TypingComponent_point{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(255,255,255, 0.54);
}


.ACCW_TypingComponent_point1{
  -webkit-animation: typingAnimation 1.7s infinite;
          animation: typingAnimation 1.7s infinite;
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s
}

.ACCW_TypingComponent_point2{
  -webkit-animation: typingAnimation 1.7s infinite;
          animation: typingAnimation 1.7s infinite;
  -webkit-animation-delay: -0.55s;
          animation-delay: -0.55s
}
.ACCW_TypingComponent_point3{
  -webkit-animation: typingAnimation 1.7s infinite;
          animation: typingAnimation 1.7s infinite;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s
}




@-webkit-keyframes typingAnimation{
  10%{
    -webkit-transform: translateY(-120%);
            transform: translateY(-120%)
  }
  50%{
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
}




@keyframes typingAnimation{
  10%{
    -webkit-transform: translateY(-120%);
            transform: translateY(-120%)
  }
  50%{
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
}


.file_upload_component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
#input_file {
  display: none;
}
.input_file_label_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
}

/* .input_file_label_image_wrapper:hover{
  background-color: rgba(0,108,255, 0.07);
} */
.input_file_label_image {
  width: 24px;
  height: 24px;
}
.input_file_label_image_loading {
  -webkit-animation: fileUpload 1s infinite;
          animation: fileUpload 1s infinite

}

@-webkit-keyframes fileUpload {
  from {
    -webkit-transform: translateY(0) ;
            transform: translateY(0) ;
  }
  to {
    -webkit-transform: translateY(-200%) ;
            transform: translateY(-200%) ;
  }
}

@keyframes fileUpload {
  from {
    -webkit-transform: translateY(0) ;
            transform: translateY(0) ;
  }
  to {
    -webkit-transform: translateY(-200%) ;
            transform: translateY(-200%) ;
  }
}
@media screen and (max-width: 460px) {
  .ACCW_Footer_Hide {
    display: none;
  }
}

.ACCW_FooterComponent {
  height: 60px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  max-width: 724px;
  margin: 0 auto;
}

.ACCW_FooterComponent_persistent_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.ACCW_FooterComponent_persistent_button_img_wraper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ACCW_FooterComponent_input_button {
  display: flex;
  align-items: center;
}

.ACCW_FooterComponent_input_button_wraper {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sand_scale {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ACCW_FooterComponent_input_button_image_wraper {
  height: 40px;
  width: 40px;
}

.ACCW_FooterComponent_input_button_send_wraper {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding-left: 3px;
  display: flex;
  margin-right: 10px;
  transition: all 0.2s;
}

.ACCW_FooterComponent_input_button_send_wraper:hover {
  background-color: rgba(0, 108, 255, 0.07);
}

.ACCW_FooterComponent_input_button_image_img {
  height: 100%;
}
.ACCW_FooterComponent_input_button_send_img {
  height: 24px;
  margin: auto;
  transition: all 0.3s;
}

.ACCW_FooterComponent_chat_input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.ACCW_ChatInputComponent_form {
  flex: 1 1;
  display: flex;
}
.ACCW_ChatInputComponent_input,
.accw_input_gradient__FALSE {
  flex: 1 1;
  padding: 10px 0px 0px 0px;
  font-family: "Montserrat", sans-serif;
  border: none;
  opacity: 0.87;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  /* background: none; */
}

.ACCW_ChatInputComponent_input::-webkit-scrollbar,
.accw_input_gradient__FALSE::-webkit-scrollbar {
  display: none !important;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
}

.ACCW_ChatInputComponent_input_locked {
  text-align: center;
  /* margin-right: 15px; */
  line-height: 25px;
}

.VoiceComponent_main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.VoiceComponent_main i {
  font-size: 24px;
}

.audio_anim {
  height: 30px;
  cursor: pointer;
}

.audioStop {
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #006cff;
  background: white;
  opacity: 0;
  transition: all 0.3s;
  font-size: 12px;
}

.VoiceComponent_main:hover .audioStop {
  opacity: 1;
}

.pNotificationComponent_fixed {
  position: fixed;
  right: -430px;
  top: 0;
  padding: 12px;
  display: flex;
  transition: all 0.3s;
  transition-timing-function: ease-in-out;
  z-index: 27001;
}

.pNotificationComponent_fixed_show {
  right: 0px;
}

.pNotificationComponent_main {
  max-width: 400px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  transition: all 0.3s;
}

.pNotificationComponent_container {
  display: flex;
  align-items: center;
  padding: 15px;
}

.pNotificationComponent_header {
  display: flex;
  justify-content: flex-end;
  /* padding-top: 5px; */
  min-height: 14px;
  align-items: center;
  padding-right: 10px;
  margin-left: -5px;
}

.pNotificationComponent_close_button {
  cursor: pointer;
}

.pNotificationComponent_close_button {
  padding: 5px;
  border-radius: 4px;
  display: flex;
  transition: all 0.3s;
  align-items: center;
}

.pNotificationComponent_close_button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.pNotificationComponent_message {
  /* padding: 15px; */
  font-family: var(--main-font);
  color: white;
  font-weight: 300;
}

.pNotificationComponent_image {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.ACCW_HeaderComponent{
  height: 60px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1);0 */
  display: grid;
   grid-template-columns: 60px minmax(0px, 3000px) 60px ;
   /* background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%); */
}

.ACCW_HeaderComponent_wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}

.ACCW_HeaderComponent_title{
  
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.ACCW_HeaderComponent_description{
  opacity: 0.87;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  margin-top: 7px;
}

.ACCW_HeaderComponent_img_wrapper{
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.2s;
  display: flex;
}
.ACCW_HeaderComponent_img_wrapper:hover{
  background:rgba(255,255,255, 0.38);

}


.ACCW_HeaderComponent_img{
  height: 14px;
  margin: auto;
}

.ACCW_HeaderComponent_i{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ACCW_HeaderComponent_AC_link{
  text-decoration: underline;
  opacity: 0.87;
  color: #ffffff;
}

.ACCW_HeaderComponent_wrapper_avatar{
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.ACCW_HeaderComponent_avatar_one{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  background: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.ACCW_HeaderComponent_avatar_img{
  margin: auto;
  height: 100%;
}
.ACCW_QuickReplieComponent_main{
  background-color: #edf5ff;
  border: 1px solid #f2f2f2;
  /* height: 40px; */
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 40px;
  margin: 5px;
  cursor: pointer;
}

.ACCW_QuickReplieComponent_main_text{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
}

.ACCW_QuickRepliesComponent_main_wrapper_button{
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 0;
}

.AACW_qr_garadient .ACCW_QuickReplieComponent_main{

}
.ACCW_FrameWrapperComponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ACCW_FrameWrapperComponent_content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    height: 1px;

}

.ACCW_FrameWrapperComponent_fotter {
    flex: 0 0 auto;
}

.ACCW_ConversationByComponent {
    height: 25px;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: center;
    align-items: center;

}

.ACCW_ConversationByComponent span {
    /* Style for "Conversati" */
    opacity: 0.54;
    color: #000000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.ACCW_ConversationByComponent a {
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
}
